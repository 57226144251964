<template>
  <v-card flat>
    <v-btn class="mb-4" fab color="primary">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <LeagueTeam
      class="mt-5"
      v-for="team in teams"
      :key="team.id"
      :team="team"
    ></LeagueTeam>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LeagueTeam from './team/LeagueTeam.vue'
export default {
  data: () => ({
    show: false
  }),
  components: { LeagueTeam },
  created() {
    this.initTeams(this.$route.params.id)
  },
  methods: {
    ...mapActions('admin', [
      'initTeams'
    ])
  },
  computed: {
    ...mapGetters('admin', [
      'teams'
    ])
  }
}
</script>