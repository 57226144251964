<template>
  <v-card
    class="mt-5"
    :key="team.id"
  >
    <v-row class="pl-5">
      <v-card-title>
        {{team.team_name}}
      </v-card-title>
      <v-chip class="mt-4">
        <div v-if="showJoinCode">
          {{team.join_code}}
        </div>
        <div v-else>
          ******
        </div>
        <v-icon @click="showJoinCode = !showJoinCode" class="ml-3" v-if="showJoinCode">mdi-eye-off-outline</v-icon>
        <v-icon @click="showJoinCode = !showJoinCode" class="ml-3" v-else>mdi-eye-outline</v-icon>
      </v-chip>
      <v-spacer></v-spacer>
      {{team.id}}
      <v-btn @click="show = true" class="mr-5 mt-3" small rounded>
        <v-icon>mdi-pencil-outline</v-icon>
      </v-btn>
    </v-row>
    <!-- {{team}} -->
    <v-dialog max-width="800" v-model="show">
      <v-card max-width="800">
        <v-card-title>
          Edit team
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: ['team'],
  data: () => ({
    showJoinCode: false,
    show: false
  })
}
</script>